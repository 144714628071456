
$bg-color: $color-gray-ultralight;
$dot-height: .25rem;
$item-h-spacing: .5rem;


/* Ärzte (Flickity-Wrapper) */

.c-aerzte {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  .no-js & {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: .8rem;
  }
}


/* Arzt (Carousel-Cell) */

.c-arzt {
  width: 31%;
  min-width: 18rem;
  height: auto;
  text-align: center;
  padding: 2rem 1rem 1rem 1rem;
  transition:$base-transition;
  transition-property: opacity;
  will-change: opacity;

  .js & {
    margin: 0 $item-h-spacing;
  }
  .js &:not(.is-selected) {
    opacity: .1;
  }
}

.c-arzt__photo {
  position: relative;
  margin-bottom: 1rem;

  img {
    width: 12rem;
    height: 12rem;
    // border-radius: 30  px;
    border-radius: 50%;//base-border-radius;
  }
}
.c-arzt__name h2 {
  z-index: 10;
  position: relative;
  @include font-size(20px);
  margin-bottom: 0;
}
.c-arzt__location {
  color: $color-gray;
  @include font-size(15px);
  font-style: italic;
}
.c-arzt__qualifications {
  @include font-size(13px);
  line-height: 1.8;
}


/* Seperator */

// .js .c-arzt__photo:after {
//   content:"";
//   position: absolute;
//   top: 50%;
//   right: -2rem;
//   transform: translateY(-50%);
//   height: 1rem;
//   width: 1rem;
//   border-radius: 50%;
//   background: $base-border-color;
// }


/* Dots */

.c-aerzte .flickity-page-dots {
  position: relative;
  display: block;
  bottom: -$dot-height;
  display: flex;
  width: 100%;
  justify-content: strech;
}
.c-aerzte .flickity-page-dots .dot {
  height: $dot-height;
  flex-grow: 1;
  margin: 0;
  border-radius: 0;
  background: $color-gray-ultralight;
  opacity: 1 !important;

  &.is-selected {
    background: $color-gray-light;
  }

  &:last-child {
    border-bottom-right-radius: $base-border-radius;
  }
  &:first-child {
    border-bottom-left-radius: $base-border-radius;
  }
}


/* Prev- / Next-Buttons */

.c-aerzte .flickity-button {
  $button-width: 2rem;
  width: $button-width;
  height: 2.5rem;
  opacity: 1 !important;
  // background: $bg-color !important;
  background: $color-highlight !important;
  color: white;
  // border-radius: 0;
  border-radius: $base-border-radius;
  box-shadow: $base-box-shadow;
  transition: $base-transition;
  transition-property: transform;
  will-change: transform;

  &[disabled] {
    color: $color-gray-light;
  }

  .flickity-button-icon {
    fill: currentColor;
    stroke: currentColor;
    width: 50% !important;
    height: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    // transition: $base-transition;
    // transition-property: transform;
    // will-change: transform;
  }

  // Differentiation
  &.previous {
    left: -$button-width / 2;
    // border-top-left-radius: $base-border-radius;
    // border-bottom-left-radius: $base-border-radius;

    .flickity-button-icon {
      // left: auto !important;
      // right: 15% !important;
    }
    &:hover {
      transform: translate3d(-2px, -50%, 0);
    }
    &:hover .flickity-button-icon {
      // transform: translate3d(-1px, 0, 0);
    }
  }
  &.next {
    right: -$button-width / 2;
    // border-top-right-radius: $base-border-radius;
    // border-bottom-right-radius: $base-border-radius;

    .flickity-button-icon {
      // right: auto !important;
      // left: 15% !important;
    }
    &:hover {
      transform: translate3d(2px, -50%, 0);
    }
    &:hover .flickity-button-icon {
      // transform: translate3d(1px, 0, 0);
    }
  }
}
