/**
* Overwrite Styling for Cookiebanner
* https://github.com/dobarkod/cookie-banner
*/


$close-circle-size: 1.2rem;
$close-icon-size: 1.2rem;
$text-color: $color-gray-light;


div.cookiebanner,
div.cookiebanner > span,
div.cookiebanner div.cookiebanner-close {
  @include font-size(15px);
  color: $text-color;
}

div.cookiebanner {
  padding: .5rem 2.5*$close-circle-size .5rem .5*$close-circle-size !important;
  background: rgba(0,0,0,.85) !important;
}

div.cookiebanner a {
  color: white !important;
  @include underlined-link-multiline($color: white, $offset: 2px);
  white-space: nowrap;
}

div.cookiebanner div.cookiebanner-close {
  background: white;
  color: black;
  background: $color-highlight;
  color: white;
  border-radius: 50%;
  width: $close-circle-size;
  height: $close-circle-size;
  line-height: 100%;
  overflow: hidden;

  font-size: $close-icon-size;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $close-circle-size;
}
