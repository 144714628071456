/**
* Mixins for consistent typography and FOIT all over the place.
*/


@mixin font-header {
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-display: fallback;
}

@mixin font-text {
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-display: fallback;
  font-weight: normal;
}



/* Sizing */

@mixin font-size($font-size, $line-height: auto) {
  font-size: $font-size;
  font-size: ($font-size / $base-font-size) * 1em;
  @if $line-height == auto {
    line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
  }
  @else {
    @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal){
      line-height: $line-height;
    }
    @elseif ($line-height != none and $line-height != false) {
      @warn 'D’oh! ‘#{$line-height}’ is not a valid value for `line-height`.';
    }
  }
}


/* Truncate Text-Overflow */

@mixin truncate() {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/**
* Break Words (esp. links) and don't overflow container.
* Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
**/
@mixin break-word() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}


/**
* Custom Multiline Underlined Links.
* NOTE: Multiline only works with `display: inline;`
*/
@mixin underlined-link-multiline($color: $color-page-link, $color-no-hover: rgba($color, .25), $width: 2px, $offset: 1px) {
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, $color-no-hover 0%, $color-no-hover $width, transparent $width);
  padding-bottom: $offset;

  &:hover,
  &:focus {
    background-image: linear-gradient(to top, transparent 0%, $color 0%, $color $width, transparent $width);
  }
}


/**
* Custom Underlined Links.
* ADVANTAGES: Easily adapt currentColor to apply hover-effect & Icon-Offset possible.
* DISADVANTAGE: No line-breaks possible.
*/
@mixin underlined-link($offset: 2px) {
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  line-height: inherit;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -1px;
    bottom: - $offset;
    width: 100%;
    width: calc(100% + 2px);
    height: 0;
    border-bottom: 1px currentColor solid;
    opacity: .2;
  }

  &:hover:after,
  &:focus:after {
    opacity: 1;
  }
}

/**
* Skips the icon of the above underlined-link. (Not the multiline-one)
*/
@mixin underlined-link--skipIcon($margin: 1em, $icon-size: 1em, $left: true) {
  &:after {
    width: calc(100% + 2px - #{$icon-size} - #{$margin}) !important;

    @if $left {
      left: calc(-1px + #{$icon-size} + #{$margin}) !important;
    } @else {
      left: auto;
      right: calc(-1px + #{$icon-size} + #{$margin}) !important;
    }
  }

  .o-icon {
    @include o-icon($icon-size);

    @if $left {
      margin-right: $margin;
    } @else {
      margin-left: $margin;
    }
  }
}


/**
* Mixin for creating smart underlining.
* Text-shadows are added to make the line look paused.
*/
@mixin underlined-link-smart($background, $color: currentColor) {

  $text-shadow: '';
  $x-half-depth: 3;
  $y-depth: 3;

  @for $x from -$x-half-depth through $x-half-depth {
    @for $y from 0 through $y-depth {
      $text-shadow: $text-shadow + '#{$x}px #{$y}px #{$background}';

      @if (($x != $x-half-depth) OR ($y != $y-depth)) {
        $text-shadow: $text-shadow + ', ';
      }
    }
  }

  text-shadow: #{$text-shadow};
  position: relative;
  white-space: nowrap;
  z-index: 10;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -1px;
    bottom: 1px;
    width: 100%;
    width: calc(100% + 2px);
    height: 0;
    border-bottom: 1px $color solid;
    z-index: -10;
    opacity: .2;
  }

  &:hover:after, &:focus:after {
    opacity: 1;
  }
}
