/* A tab component based on: https://codepen.io/oknoblich/pen/tfjFl/ */

$border-color: $color-gray-light;


.c-tabs {
  text-align: center;
}


input.c-tab + label {
  @include font-size(17px);
  display: inline-block;
  position: relative;
  padding: .75rem 1.2rem;
  outline: none;
  font-weight: 600;
  text-align: center;
  color: $color-gray;
  border-top-left-radius: $base-border-radius;
  border-top-right-radius: $base-border-radius;
  user-select: none;

  h2 {
    display: inline !important;
    outline: 2px red solid;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
  }

  &:first-of-type {
    margin-left: .5rem;
  }
  &:last-of-type {
    margin-right: .5rem;
  }

  @media (max-width: 25rem) {
    padding: .75rem 1rem;
  }
  @media (max-width: 20rem) {
    width: 100%;
    @include truncate;
    border-radius: $base-border-radius;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: .75em .5rem;

    &:last-of-type {
      margin-bottom: .5rem;
    }
    &:after {
      content: none !important;
    }
  }
}

input.c-tab:hover + label {
  color: #888;
  cursor: pointer;
}

input.c-tab:checked + label {
  position: relative;
  color: $color-highlight;
  background: $section-bg;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 3rem);
    left: 1.5rem;
    height: 2px;
    background: $color-highlight;
  }
}


.c-tab-content {
  position: relative;
  z-index: 50;
  text-align: left;
}


/* Logic */

input.c-tab {
  @include visually-hidden();
}

// Source: https://stackoverflow.com/a/17559740/1381666
// NOTE: This is a little more complicated than it could be to save some bytes ;)
$selector : '';
@for $i from 1 through 10 {
    $selector : $selector + '.c-tab:nth-of-type(' + $i + '):not(:checked) ~ .c-tab-content:nth-of-type(' + $i + ')' + ', ';
}
#{$selector} {
  @include visually-hidden();
}
