
$item-v-spacing: .5rem;
$item-h-spacing: 1rem;
$item-larger-v-spacing: .75rem;
$item-larger-h-spacing: 1rem;


.c-link-list {
  padding: .75rem 0;
  margin: #{-$item-v-spacing} #{-$item-h-spacing};
  display: flex;
  flex-direction: column;
}

.c-link-list__link {
  list-style: none;
  padding: #{$item-v-spacing} #{$item-h-spacing};
  color: $color-gray;

  h2 {
    @include font-size(17px);
    margin-bottom: 0;
  }

  > a {
    @include underlined-link-multiline();
    font-weight: 600;
  }

  p {
    @include font-size(14px);
    margin: .15rem 0 0 0;
  }
  &--italicText p {
    font-style: italic;
  }

  // No-Links (e.g. Fax)
  span {
    @include underlined-link-multiline($color: $color-gray-light);
    pointer-events: none;
    text-decoration: none;
  }

  // Links in Subtitle
  p a {
    @include underlined-link-multiline($color: $color-gray);
    text-decoration: none;
    color: inherit;
  }

}


/* Alternative Color */

.c-link-list--altColor .c-link-list__link a {
  color: $color-highlight-secondary;
  @include underlined-link-multiline($color: $color-highlight-secondary);
}


/* Truncate more than one line */

.c-link-list--truncateText .c-link-list__link {
  // @include truncate();
}


/* Fading out Elements */

.c-link-list--fadeLinksOut .c-link-list__link {
  $elements: 7;
  $fade-amount: .75 / $elements;

  transition: opacity $base-transition-parameters;
  will-change: opacity;

  @for $i from 0 to $elements {
    &:not(:hover):not(:focus):nth-child(#{$i + 1}) {
      opacity: 1 - $fade-amount * $i;
    }
  }

  &:not(:hover):not(:focus):nth-child(n + #{$elements + 1}) {
    opacity: 1 - $fade-amount * $elements;
  }
}


/* Griddy (e.g. Leistungen) */

.c-link-list--isGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .c-link-list__link {
    flex: 1 1 15em;
  }
}


/* Text Centered */

.c-link-list--isCentered {
  text-align: center;
  align-items: center;
}


/* Larger Items */

.c-link-list--isLarger {
  margin: #{-$item-larger-v-spacing} #{-$item-larger-h-spacing};

  .c-link-list__link {
    padding: #{$item-larger-v-spacing} #{$item-larger-h-spacing};
  }

  .c-link-list__link a {
    @include font-size(17px);
  }

  .c-link-list__link p {
    @include font-size(15px);
    margin-top: .25em;
  }
}
