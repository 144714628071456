

.c-videosprechstunde-info {
  @include font-size(15px);
  text-align: center;
  max-width: 32rem;
  margin: $base-spacing-unit-small auto;

  &__head svg {
    margin: -$base-font-size-smaller 0;
    fill: #2298f3;
    stroke-width: 0;
    height: 10rem * (42 / 143);
    width: 10rem;
  }
}

.c-videosprechstunde-headline {
  margin-top: 1em;
  @include font-size(16px);
  color: $color-gray;
  font-weight: normal;
  font-style: italic;
  text-align: center;
}

.c-videosprechstunde-table,
table.c-videosprechstunde-table {
  width: 100%;
  width: calc(100% + 4rem);
  margin: 2rem -2rem 0;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: .85rem;

  td, th {
    padding: .5rem;
  }
  tbody tr td {
    text-align: center;
  }

  // Empty Schedule
  td.empty::after {
    content:"⁄";
    color: rgba(black, .25);
  }

  // Alternate Row-Coloring
  tbody tr:nth-child(odd) td {
    background: rgba(black, .04);
  }

  // Reponsiveness
  &--hideOnSmall {
    display: none;

    tbody tr:first-of-type td {
      font-weight: 600;
    }
  }
  &--showOnSmall {
    tbody tr td:first-of-type {
      font-weight: 600;
    }
    // tbody tr td:not(:first-of-type) {
    //   text-align: center;
    // }
  }

  @media (min-width: 40rem) {
    &--hideOnSmall {
      display: table;
    }
    &--showOnSmall {
      display: none;
    }
  }
}

.c-videosprechstunde-links {
  @include font-size(15px);
  margin: $base-spacing-unit -2rem 0 -2rem;
  z-index: 100;
  position: relative;

  &__items {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__item {
    border-top: 1px darken($color-gray-ultralight, 5) solid;
    text-decoration: none;
    padding: .5em 1em;
    color: black;

    &:hover {
      background: darken($color-gray-ultralight, 5);
    }
    &:last-child {
      border-bottom: 1px darken($color-gray-ultralight, 5) solid;
    }
  }
}
