/**
* Columns-Plugin
* https://github.com/getkirby-plugins/columns-plugin
*/


$columns-spacing: 1rem;


.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.column {
  width: 100%;
  vertical-align: top;
}

// Calculate respective min-widths for n-sized columns to wrap on one line
@for $n from 1 through 5 {
  @media (min-width: $n * 22rem) {
    .columns.columns-#{$n} {
      flex-wrap: nowrap;
      > .column {
        width: calc(#{100% / $n} - #{$columns-spacing});
      }

      // Seperator
      &.columns--hasSeperator .column {
        position: relative;
      }
      &.columns--hasSeperator .column:not(:last-of-type):after {
        content:"";
        position: absolute;
        width: 2px;
        right: 0;
        top: 1.25rem;
        bottom: 1.25rem;
        background: $base-border-color;
      }
    }
  }
}


/* Improve Tables in Columns */

.column table {
  margin-left: auto;
  margin-right: auto;
}
.columns:not(.columns-1) .column table {
  width: 100%;
}


/* Center Columns */

.columns--centerColumns {
  justify-content: center;
}
