@charset "UTF-8";
/**
* Mixins for consistent typography and FOIT all over the place.
*/
/* Sizing */
/* Truncate Text-Overflow */
/**
* Break Words (esp. links) and don't overflow container.
* Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
**/
/**
* Custom Multiline Underlined Links.
* NOTE: Multiline only works with `display: inline;`
*/
/**
* Custom Underlined Links.
* ADVANTAGES: Easily adapt currentColor to apply hover-effect & Icon-Offset possible.
* DISADVANTAGE: No line-breaks possible.
*/
/**
* Skips the icon of the above underlined-link. (Not the multiline-one)
*/
/**
* Mixin for creating smart underlining.
* Text-shadows are added to make the line look paused.
*/
/**
* Columns-Plugin
* https://github.com/getkirby-plugins/columns-plugin
*/
.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto; }

.column {
  width: 100%;
  vertical-align: top; }

@media (min-width: 22rem) {
  .columns.columns-1 {
    flex-wrap: nowrap; }
    .columns.columns-1 > .column {
      width: calc(100% - 1rem); }
    .columns.columns-1.columns--hasSeperator .column {
      position: relative; }
    .columns.columns-1.columns--hasSeperator .column:not(:last-of-type):after {
      content: "";
      position: absolute;
      width: 2px;
      right: 0;
      top: 1.25rem;
      bottom: 1.25rem;
      background: #f1f1f1; } }

@media (min-width: 44rem) {
  .columns.columns-2 {
    flex-wrap: nowrap; }
    .columns.columns-2 > .column {
      width: calc(50% - 1rem); }
    .columns.columns-2.columns--hasSeperator .column {
      position: relative; }
    .columns.columns-2.columns--hasSeperator .column:not(:last-of-type):after {
      content: "";
      position: absolute;
      width: 2px;
      right: 0;
      top: 1.25rem;
      bottom: 1.25rem;
      background: #f1f1f1; } }

@media (min-width: 66rem) {
  .columns.columns-3 {
    flex-wrap: nowrap; }
    .columns.columns-3 > .column {
      width: calc(33.33333% - 1rem); }
    .columns.columns-3.columns--hasSeperator .column {
      position: relative; }
    .columns.columns-3.columns--hasSeperator .column:not(:last-of-type):after {
      content: "";
      position: absolute;
      width: 2px;
      right: 0;
      top: 1.25rem;
      bottom: 1.25rem;
      background: #f1f1f1; } }

@media (min-width: 88rem) {
  .columns.columns-4 {
    flex-wrap: nowrap; }
    .columns.columns-4 > .column {
      width: calc(25% - 1rem); }
    .columns.columns-4.columns--hasSeperator .column {
      position: relative; }
    .columns.columns-4.columns--hasSeperator .column:not(:last-of-type):after {
      content: "";
      position: absolute;
      width: 2px;
      right: 0;
      top: 1.25rem;
      bottom: 1.25rem;
      background: #f1f1f1; } }

@media (min-width: 110rem) {
  .columns.columns-5 {
    flex-wrap: nowrap; }
    .columns.columns-5 > .column {
      width: calc(20% - 1rem); }
    .columns.columns-5.columns--hasSeperator .column {
      position: relative; }
    .columns.columns-5.columns--hasSeperator .column:not(:last-of-type):after {
      content: "";
      position: absolute;
      width: 2px;
      right: 0;
      top: 1.25rem;
      bottom: 1.25rem;
      background: #f1f1f1; } }

/* Improve Tables in Columns */
.column table {
  margin-left: auto;
  margin-right: auto; }

.columns:not(.columns-1) .column table {
  width: 100%; }

/* Center Columns */
.columns--centerColumns {
  justify-content: center; }

/* Ärzte (Flickity-Wrapper) */
.c-aerzte {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .no-js .c-aerzte {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: .8rem; }

/* Arzt (Carousel-Cell) */
.c-arzt {
  width: 31%;
  min-width: 18rem;
  height: auto;
  text-align: center;
  padding: 2rem 1rem 1rem 1rem;
  transition: all 0.15s ease-in-out;
  transition-property: opacity;
  will-change: opacity; }
  .js .c-arzt {
    margin: 0 0.5rem; }
  .js .c-arzt:not(.is-selected) {
    opacity: .1; }

.c-arzt__photo {
  position: relative;
  margin-bottom: 1rem; }
  .c-arzt__photo img {
    width: 12rem;
    height: 12rem;
    border-radius: 50%; }

.c-arzt__name h2 {
  z-index: 10;
  position: relative;
  font-size: 20px;
  font-size: 1.25em;
  line-height: 1.3;
  margin-bottom: 0; }

.c-arzt__location {
  color: #9F9F9F;
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  font-style: italic; }

.c-arzt__qualifications {
  font-size: 13px;
  font-size: 0.8125em;
  line-height: 2;
  line-height: 1.8; }

/* Seperator */
/* Dots */
.c-aerzte .flickity-page-dots {
  position: relative;
  display: block;
  bottom: -0.25rem;
  display: flex;
  width: 100%;
  justify-content: strech; }

.c-aerzte .flickity-page-dots .dot {
  height: 0.25rem;
  flex-grow: 1;
  margin: 0;
  border-radius: 0;
  background: #F9F9F9;
  opacity: 1 !important; }
  .c-aerzte .flickity-page-dots .dot.is-selected {
    background: #D5D5D5; }
  .c-aerzte .flickity-page-dots .dot:last-child {
    border-bottom-right-radius: 4px; }
  .c-aerzte .flickity-page-dots .dot:first-child {
    border-bottom-left-radius: 4px; }

/* Prev- / Next-Buttons */
.c-aerzte .flickity-button {
  width: 2rem;
  height: 2.5rem;
  opacity: 1 !important;
  background: #E28131 !important;
  color: white;
  border-radius: 4px;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.15s ease-in-out;
  transition-property: transform;
  will-change: transform; }
  .c-aerzte .flickity-button[disabled] {
    color: #D5D5D5; }
  .c-aerzte .flickity-button .flickity-button-icon {
    fill: currentColor;
    stroke: currentColor;
    width: 50% !important;
    height: auto !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%); }
  .c-aerzte .flickity-button.previous {
    left: -1rem; }
    .c-aerzte .flickity-button.previous:hover {
      transform: translate3d(-2px, -50%, 0); }
  .c-aerzte .flickity-button.next {
    right: -1rem; }
    .c-aerzte .flickity-button.next:hover {
      transform: translate3d(2px, -50%, 0); }

.c-videosprechstunde-info {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  text-align: center;
  max-width: 32rem;
  margin: 0.5rem auto; }
  .c-videosprechstunde-info__head svg {
    margin: -13px 0;
    fill: #2298f3;
    stroke-width: 0;
    height: 2.93706rem;
    width: 10rem; }

.c-videosprechstunde-headline {
  margin-top: 1em;
  font-size: 16px;
  font-size: 1em;
  line-height: 1.625;
  color: #9F9F9F;
  font-weight: normal;
  font-style: italic;
  text-align: center; }

.c-videosprechstunde-table,
table.c-videosprechstunde-table {
  width: 100%;
  width: calc(100% + 4rem);
  margin: 2rem -2rem 0;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: .85rem; }
  .c-videosprechstunde-table td, .c-videosprechstunde-table th,
  table.c-videosprechstunde-table td,
  table.c-videosprechstunde-table th {
    padding: .5rem; }
  .c-videosprechstunde-table tbody tr td,
  table.c-videosprechstunde-table tbody tr td {
    text-align: center; }
  .c-videosprechstunde-table td.empty::after,
  table.c-videosprechstunde-table td.empty::after {
    content: "⁄";
    color: rgba(0, 0, 0, 0.25); }
  .c-videosprechstunde-table tbody tr:nth-child(odd) td,
  table.c-videosprechstunde-table tbody tr:nth-child(odd) td {
    background: rgba(0, 0, 0, 0.04); }
  .c-videosprechstunde-table--hideOnSmall,
  table.c-videosprechstunde-table--hideOnSmall {
    display: none; }
    .c-videosprechstunde-table--hideOnSmall tbody tr:first-of-type td,
    table.c-videosprechstunde-table--hideOnSmall tbody tr:first-of-type td {
      font-weight: 600; }
  .c-videosprechstunde-table--showOnSmall tbody tr td:first-of-type,
  table.c-videosprechstunde-table--showOnSmall tbody tr td:first-of-type {
    font-weight: 600; }
  @media (min-width: 40rem) {
    .c-videosprechstunde-table--hideOnSmall,
    table.c-videosprechstunde-table--hideOnSmall {
      display: table; }
    .c-videosprechstunde-table--showOnSmall,
    table.c-videosprechstunde-table--showOnSmall {
      display: none; } }

.c-videosprechstunde-links {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  margin: 1.5rem -2rem 0 -2rem;
  z-index: 100;
  position: relative; }
  .c-videosprechstunde-links__items {
    display: flex;
    flex-direction: column;
    text-align: center; }
  .c-videosprechstunde-links__item {
    border-top: 1px #ececec solid;
    text-decoration: none;
    padding: .5em 1em;
    color: black; }
    .c-videosprechstunde-links__item:hover {
      background: #ececec; }
    .c-videosprechstunde-links__item:last-child {
      border-bottom: 1px #ececec solid; }

.c-important-notice {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  background: rgba(255, 89, 100, 0.1);
  margin: 1.5rem auto;
  max-width: 44rem;
  padding: 0.5rem 1.5rem;
  border-radius: 4px; }
  .c-important-notice > p:first-child {
    margin-top: 0; }
  .c-important-notice > p:last-child {
    margin-bottom: 0; }
  .c-important-notice--red {
    color: #FF5964; }

.c-sprechzeiten__info {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  text-align: center;
  max-width: 70ch;
  margin: 0.5rem auto; }

.c-sprechzeiten h3 {
  margin-top: 1em;
  font-size: 16px;
  font-size: 1em;
  line-height: 1.625;
  color: #9F9F9F;
  font-weight: normal;
  font-style: italic;
  text-align: center; }

.c-kontakt__info {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  text-align: center;
  max-width: 75ch;
  margin: 0.5rem auto;
  margin-bottom: 1.5rem; }

/* GLightbox Modifications */
.glightbox-open .glightbox-clean .gclose {
  opacity: 1;
  top: 2vh !important;
  right: 2vw !important;
  border-radius: 4px;
  background-color: black;
  background-position: center center;
  background-size: 50%;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M23.707,22.293l-10.293,-10.293l10.292,-10.293c0.391,-0.39 0.391,-1.023 0,-1.414c-0.391,-0.391 -1.023,-0.391 -1.414,0l-10.292,10.293l-10.294,-10.294c-0.391,-0.39 -1.023,-0.39 -1.414,2.22045e-16c-0.391,0.391 -0.391,1.023 0,1.414l10.294,10.294l-10.294,10.294c-0.39,0.39 -0.391,1.024 2.22045e-16,1.414c0.391,0.391 1.024,0.39 1.414,0l10.294,-10.294l10.293,10.292c0.39,0.391 1.023,0.391 1.414,0c0.391,-0.39 0.39,-1.023 0,-1.413Z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E"); }

.glightbox-open .glightbox-clean .ginner-container {
  border-radius: 4px;
  overflow: hidden; }

.glightbox-open .glightbox-clean .gslide-description {
  background: #f2f2f2; }

.glightbox-open .glightbox-clean .gslide-description.description-bottom {
  border-top: 1px gainsboro solid;
  margin-top: -2rem; }

.glightbox-open .glightbox-clean .gslide-description.description-top {
  border-bottom: 1px gainsboro solid; }

.glightbox-open .glightbox-clean .gslide-external {
  max-height: 100vh !important;
  max-width: 100vw !important; }

.glightbox-open .gnext,
.glightbox-open .gprev {
  display: none !important; }

.glightbox-open .glightbox-clean .gslide-external > iframe {
  height: 700px !important;
  padding-top: 1rem !important;
  max-height: 100vh !important; }

.c-jobs {
  padding: .75rem 1.2rem;
  text-align: center;
  margin: -1.5rem auto; }
  .c-jobs h3 {
    font-size: 18px;
    font-size: 1.125em;
    line-height: 1.44444; }

.c-job {
  margin: 1.5rem auto;
  position: relative;
  max-width: 80ch; }
  .c-job h4 {
    margin-top: 1.5em;
    margin-bottom: .25em;
    font-size: 16px;
    font-size: 1em;
    line-height: 1.625;
    color: #9F9F9F;
    font-weight: normal; }
  .c-job p {
    font-size: 16px;
    font-size: 1em;
    line-height: 1.625;
    margin: 0; }
  .c-job:not(:last-of-type):after {
    content: "";
    margin-top: 1.5rem;
    display: block;
    border-bottom: 2px #f1f1f1 solid; }

/* Headings */
.c-section--article .c-section__main h1:first-child, .c-section--article .c-section__main h2:first-child, .c-section--article .c-section__main h3:first-child, .c-section--article .c-section__main h4:first-child {
  margin-top: .5em; }

.c-section--article .c-section__main h1:not(:first-child), .c-section--article .c-section__main h2:not(:first-child), .c-section--article .c-section__main h3:not(:first-child), .c-section--article .c-section__main h4:not(:first-child) {
  margin-top: 1.5em; }

.c-section--article .c-section__main h1 {
  font-size: 28px;
  font-size: 1.75em;
  line-height: 35px; }

.c-section--article .c-section__main h1:not(:first-child):before,
.c-section--article .c-section__main h2:not(:first-child):before {
  content: "";
  display: block;
  margin: 0 auto 1.5em auto;
  width: 10rem;
  border-top: 2px #f1f1f1 solid; }

.c-section--article .c-section__main h4 {
  text-transform: uppercase; }

/* Links */
.c-section--article .c-section__main a {
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, rgba(226, 129, 49, 0.25) 0%, rgba(226, 129, 49, 0.25) 2px, transparent 2px);
  padding-bottom: 1px; }
  .c-section--article .c-section__main a:hover, .c-section--article .c-section__main a:focus {
    background-image: linear-gradient(to top, transparent 0%, #E28131 0%, #E28131 2px, transparent 2px); }

/* HR */
.c-section--article .c-section__main hr {
  display: block;
  width: 10rem;
  margin: 2em auto;
  text-align: left;
  border: none;
  border-top: 2px #f1f1f1 solid; }

/* A tab component based on: https://codepen.io/oknoblich/pen/tfjFl/ */
.c-tabs {
  text-align: center; }

input.c-tab + label {
  font-size: 17px;
  font-size: 1.0625em;
  line-height: 1.52941;
  display: inline-block;
  position: relative;
  padding: .75rem 1.2rem;
  outline: none;
  font-weight: 600;
  text-align: center;
  color: #9F9F9F;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  user-select: none; }
  input.c-tab + label h2 {
    display: inline !important;
    outline: 2px red solid;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0; }
  input.c-tab + label:first-of-type {
    margin-left: .5rem; }
  input.c-tab + label:last-of-type {
    margin-right: .5rem; }
  @media (max-width: 25rem) {
    input.c-tab + label {
      padding: .75rem 1rem; } }
  @media (max-width: 20rem) {
    input.c-tab + label {
      width: 100%;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 4px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: .75em .5rem; }
      input.c-tab + label:last-of-type {
        margin-bottom: .5rem; }
      input.c-tab + label:after {
        content: none !important; } }

input.c-tab:hover + label {
  color: #888;
  cursor: pointer; }

input.c-tab:checked + label {
  position: relative;
  color: #E28131;
  background: #F9F9F9; }
  input.c-tab:checked + label:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 3rem);
    left: 1.5rem;
    height: 2px;
    background: #E28131; }

.c-tab-content {
  position: relative;
  z-index: 50;
  text-align: left; }

/* Logic */
input.c-tab {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px; }

.c-tab:nth-of-type(1):not(:checked) ~ .c-tab-content:nth-of-type(1), .c-tab:nth-of-type(2):not(:checked) ~ .c-tab-content:nth-of-type(2), .c-tab:nth-of-type(3):not(:checked) ~ .c-tab-content:nth-of-type(3), .c-tab:nth-of-type(4):not(:checked) ~ .c-tab-content:nth-of-type(4), .c-tab:nth-of-type(5):not(:checked) ~ .c-tab-content:nth-of-type(5), .c-tab:nth-of-type(6):not(:checked) ~ .c-tab-content:nth-of-type(6), .c-tab:nth-of-type(7):not(:checked) ~ .c-tab-content:nth-of-type(7), .c-tab:nth-of-type(8):not(:checked) ~ .c-tab-content:nth-of-type(8), .c-tab:nth-of-type(9):not(:checked) ~ .c-tab-content:nth-of-type(9), .c-tab:nth-of-type(10):not(:checked) ~ .c-tab-content:nth-of-type(10) {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px; }

/* A component styling the HTML <table> */
.c-table {
  max-width: 100%;
  margin: 0.5rem 0;
  table-layout: fixed;
  line-height: 1.6; }
  .c-table thead tr {
    background: #232323;
    color: #D5D5D5; }
  .c-table th {
    padding: .6em 1em;
    font-weight: normal; }
  .c-table td {
    padding: .5em .5em; }

.c-table.c-table-hasTwoColumns tr td:first-of-type {
  text-align: right; }

.c-table.c-table-hasTwoColumns tr td:last-of-type {
  font-weight: 600; }

.c-link-list {
  padding: .75rem 0;
  margin: -0.5rem -1rem;
  display: flex;
  flex-direction: column; }

.c-link-list__link {
  list-style: none;
  padding: 0.5rem 1rem;
  color: #9F9F9F; }
  .c-link-list__link h2 {
    font-size: 17px;
    font-size: 1.0625em;
    line-height: 1.52941;
    margin-bottom: 0; }
  .c-link-list__link > a {
    text-decoration: none;
    background-image: linear-gradient(to top, transparent 0%, rgba(226, 129, 49, 0.25) 0%, rgba(226, 129, 49, 0.25) 2px, transparent 2px);
    padding-bottom: 1px;
    font-weight: 600; }
    .c-link-list__link > a:hover, .c-link-list__link > a:focus {
      background-image: linear-gradient(to top, transparent 0%, #E28131 0%, #E28131 2px, transparent 2px); }
  .c-link-list__link p {
    font-size: 14px;
    font-size: 0.875em;
    line-height: 1.85714;
    margin: .15rem 0 0 0; }
  .c-link-list__link--italicText p  {
    font-style: italic; }
  .c-link-list__link span {
    text-decoration: none;
    background-image: linear-gradient(to top, transparent 0%, rgba(213, 213, 213, 0.25) 0%, rgba(213, 213, 213, 0.25) 2px, transparent 2px);
    padding-bottom: 1px;
    pointer-events: none;
    text-decoration: none; }
    .c-link-list__link span:hover, .c-link-list__link span:focus {
      background-image: linear-gradient(to top, transparent 0%, #D5D5D5 0%, #D5D5D5 2px, transparent 2px); }
  .c-link-list__link p a {
    text-decoration: none;
    background-image: linear-gradient(to top, transparent 0%, rgba(159, 159, 159, 0.25) 0%, rgba(159, 159, 159, 0.25) 2px, transparent 2px);
    padding-bottom: 1px;
    text-decoration: none;
    color: inherit; }
    .c-link-list__link p a:hover, .c-link-list__link p a:focus {
      background-image: linear-gradient(to top, transparent 0%, #9F9F9F 0%, #9F9F9F 2px, transparent 2px); }

/* Alternative Color */
.c-link-list--altColor .c-link-list__link a {
  color: #4F7CD6;
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, rgba(79, 124, 214, 0.25) 0%, rgba(79, 124, 214, 0.25) 2px, transparent 2px);
  padding-bottom: 1px; }
  .c-link-list--altColor .c-link-list__link a:hover, .c-link-list--altColor .c-link-list__link a:focus {
    background-image: linear-gradient(to top, transparent 0%, #4F7CD6 0%, #4F7CD6 2px, transparent 2px); }

/* Truncate more than one line */
/* Fading out Elements */
.c-link-list--fadeLinksOut .c-link-list__link {
  transition: opacity 0.15s ease-in-out;
  will-change: opacity; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(1) {
    opacity: 1; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(2) {
    opacity: 0.89286; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(3) {
    opacity: 0.78571; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(4) {
    opacity: 0.67857; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(5) {
    opacity: 0.57143; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(6) {
    opacity: 0.46429; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(7) {
    opacity: 0.35714; }
  .c-link-list--fadeLinksOut .c-link-list__link:not(:hover):not(:focus):nth-child(n + 8) {
    opacity: 0.25; }

/* Griddy (e.g. Leistungen) */
.c-link-list--isGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .c-link-list--isGrid .c-link-list__link {
    flex: 1 1 15em; }

/* Text Centered */
.c-link-list--isCentered {
  text-align: center;
  align-items: center; }

/* Larger Items */
.c-link-list--isLarger {
  margin: -0.75rem -1rem; }
  .c-link-list--isLarger .c-link-list__link {
    padding: 0.75rem 1rem; }
  .c-link-list--isLarger .c-link-list__link a {
    font-size: 17px;
    font-size: 1.0625em;
    line-height: 1.52941; }
  .c-link-list--isLarger .c-link-list__link p {
    font-size: 15px;
    font-size: 0.9375em;
    line-height: 1.73333;
    margin-top: .25em; }

a.c-action-btn,
button.c-action-btn,
.c-action-btn {
  font-size: 17px;
  font-size: 1.0625em;
  line-height: 1.52941;
  border-radius: 4px;
  background: #232323;
  color: white;
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  border: 0;
  padding: 1rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  will-change: transform, box-shadow, background, opacity;
  transition: all 0.15s ease-in-out;
  transition-property: transform, box-shadow, background, opacity;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.15); }
  a.c-action-btn:hover, a.c-action-btn:focus,
  button.c-action-btn:hover,
  button.c-action-btn:focus,
  .c-action-btn:hover,
  .c-action-btn:focus {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.11);
    background: #2d2d2d; }
  a.c-action-btn .o-icon,
  button.c-action-btn .o-icon,
  .c-action-btn .o-icon {
    top: -0.125em;
    position: relative;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    vertical-align: middle;
    margin-right: .5rem; }
  a.c-action-btn--isPrimary,
  button.c-action-btn--isPrimary,
  .c-action-btn--isPrimary {
    color: white;
    background: #E28131; }
  a.c-action-btn--isPrimary:hover, a.c-action-btn--isPrimary:focus,
  button.c-action-btn--isPrimary:hover,
  button.c-action-btn--isPrimary:focus,
  .c-action-btn--isPrimary:hover,
  .c-action-btn--isPrimary:focus {
    background: #e58c43; }
  a.c-action-btn[disabled],
  button.c-action-btn[disabled],
  .c-action-btn[disabled] {
    opacity: .8;
    box-shadow: none;
    transform: none;
    pointer-events: none; }
  a.c-action-btn--isFullWidth,
  button.c-action-btn--isFullWidth,
  .c-action-btn--isFullWidth {
    width: 100%; }

/* Loading Animation */
@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

a.c-action-btn--isLoading,
button.c-action-btn--isLoading,
.c-action-btn--isLoading {
  opacity: .8;
  box-shadow: none;
  transform: none;
  pointer-events: none; }

a.c-action-btn:not(.c-action-btn--isLoading) *[spinner],
a.c-action-btn.c-action-btn--isLoading *[no-spinner],
button.c-action-btn:not(.c-action-btn--isLoading) *[spinner],
button.c-action-btn.c-action-btn--isLoading *[no-spinner],
.c-action-btn:not(.c-action-btn--isLoading) *[spinner],
.c-action-btn.c-action-btn--isLoading *[no-spinner] {
  display: none; }

a.c-action-btn *[spinner] .o-icon,
button.c-action-btn *[spinner] .o-icon,
.c-action-btn *[spinner] .o-icon {
  animation: spin 1s linear infinite; }

.c-footer {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  margin-bottom: 3rem; }

.c-footer__line {
  display: flex;
  justify-content: center;
  margin: -0.75em -0.75em; }
  .c-footer__line--isCopyright {
    color: #9F9F9F; }

.c-footer__line > * {
  margin: 0.75em 0.75em; }

.c-footer__line a {
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, rgba(226, 129, 49, 0.25) 0%, rgba(226, 129, 49, 0.25) 2px, transparent 2px);
  padding-bottom: 1px;
  font-weight: 600; }
  .c-footer__line a:hover, .c-footer__line a:focus {
    background-image: linear-gradient(to top, transparent 0%, #E28131 0%, #E28131 2px, transparent 2px); }

/**
* Overwrite Styling for Cookiebanner
* https://github.com/dobarkod/cookie-banner
*/
div.cookiebanner,
div.cookiebanner > span,
div.cookiebanner div.cookiebanner-close {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.73333;
  color: #D5D5D5; }

div.cookiebanner {
  padding: 0.5rem 3rem 0.5rem 0.6rem !important;
  background: rgba(0, 0, 0, 0.85) !important; }

div.cookiebanner a {
  color: white !important;
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 2px, transparent 2px);
  padding-bottom: 2px;
  white-space: nowrap; }
  div.cookiebanner a:hover, div.cookiebanner a:focus {
    background-image: linear-gradient(to top, transparent 0%, white 0%, white 2px, transparent 2px); }

div.cookiebanner div.cookiebanner-close {
  background: white;
  color: black;
  background: #E28131;
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 100%;
  overflow: hidden;
  font-size: 1.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem; }
