
/* Headings */

.c-section--article .c-section__main {
  h1, h2, h3, h4 {
    &:first-child {
      margin-top: .5em;
    }
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  h1 {
    // Same size as h2 (see _elements.headings.scss)
    @include font-size(28px, 35px);
  }

  h1:not(:first-child):before, 
  h2:not(:first-child):before {
    content: "";
    display: block;
    margin: 0 auto 1.5em auto;
    width: 10rem;
    border-top: $base-border;
  }
  h4 {
    text-transform: uppercase;
  }
}


/* Links */

.c-section--article .c-section__main a {
  @include underlined-link-multiline();

}


/* HR */

.c-section--article .c-section__main hr {
  display: block;
  width: 10rem;
  margin: 2em auto;
  text-align: left;
  border: none;
  border-top: $base-border;
}
