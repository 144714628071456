
.c-important-notice {
  @include font-size(15px);
  background: rgba($color-red, .1);
  margin: $base-spacing-unit auto;
  max-width: 44rem;
  padding: $base-spacing-unit-small $base-spacing-unit;
  border-radius: $base-border-radius;

  > p:first-child {
    margin-top: 0;
  }
  > p:last-child {
    margin-bottom: 0;
  }

  &--red {
    color: $color-red;
  }
}

.c-sprechzeiten__info {
  @include font-size(15px);
  // font-weight: 600 !important;
  text-align: center;
  max-width: 70ch;
  margin: $base-spacing-unit-small auto;
  // font-style: italic;
}

.c-sprechzeiten h3 {
  margin-top: 1em;
  @include font-size(16px);
  color: $color-gray;
  font-weight: normal;
  font-style: italic;
  text-align: center;
}
