/* A component styling the HTML <table> */


.c-table {
  max-width: 100%;
  margin: $base-spacing-unit-small 0;
  table-layout: fixed;
  line-height: 1.6;

  thead tr {
    background: $color-gray-ultradark;
    color: $color-gray-light;
  }
  th {
    padding: .6em 1em;
    font-weight: normal;
  }
  td {
    padding: .5em .5em;
  }
}

.c-table.c-table-hasTwoColumns {
  tr td:first-of-type {
    text-align: right;
  }
  tr td:last-of-type {
    font-weight: 600;
  }
}
