
a.c-action-btn,
button.c-action-btn,
.c-action-btn {
  @include font-size(17px);
  border-radius: $base-border-radius;
  background: $color-gray-ultradark;
  color: white;
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  border: 0;
  padding: 1rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  will-change: transform, box-shadow, background, opacity;
  transition: $base-transition;
  transition-property: transform, box-shadow, background, opacity;
  box-shadow: $base-box-shadow;

  &:hover, &:focus {
    transform: translate3d(0, -2px, 0);
    box-shadow: $base-box-shadow--hover;
    background: lighten($color-gray-ultradark, 4);
  }

  .o-icon {
    @include o-icon(1.25em);
    margin-right: .5rem;
  }

  // Primary
  &--isPrimary {
    color: white;
    background: $color-highlight;
  }
  &--isPrimary:hover, &--isPrimary:focus {
    background: lighten($color-highlight, 4);
  }

  // Disabled
  &[disabled] {
    opacity: .8;
    box-shadow: none;
    transform: none;
    pointer-events: none;
  }


  // Full Width
  &--isFullWidth {
    width: 100%;
  }
}


/* Loading Animation */

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

a.c-action-btn,
button.c-action-btn,
.c-action-btn {

  &--isLoading {
    opacity: .8;
    box-shadow: none;
    transform: none;
    pointer-events: none;
  }

  &:not(.c-action-btn--isLoading) *[spinner],
  &.c-action-btn--isLoading *[no-spinner] {
    display: none;
  }

  *[spinner] .o-icon {
    animation: spin 1s linear infinite;
  }

}
