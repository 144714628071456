$item-v-spacing: .75em;
$item-h-spacing: .75em;

.c-footer {
  @include font-size(15px);
  margin-bottom: 3rem;
}

.c-footer__line {
  display: flex;
  justify-content: center;
  margin: #{-$item-v-spacing} #{-$item-h-spacing};

  &--isCopyright {
    color: $color-gray;
  }
}

.c-footer__line > * {
  margin: $item-v-spacing $item-h-spacing;
}

.c-footer__line a {
  @include underlined-link-multiline();
  font-weight: 600;
  // text-decoration: none;
}
