/* GLightbox Modifications */

.glightbox-open .glightbox-clean .gclose {
  opacity: 1;
  top: 2vh !important;
  right: 2vw !important;
  border-radius: $base-border-radius;
  background-color: black;
  background-position: center center ;
  background-size: 50%;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M23.707,22.293l-10.293,-10.293l10.292,-10.293c0.391,-0.39 0.391,-1.023 0,-1.414c-0.391,-0.391 -1.023,-0.391 -1.414,0l-10.292,10.293l-10.294,-10.294c-0.391,-0.39 -1.023,-0.39 -1.414,2.22045e-16c-0.391,0.391 -0.391,1.023 0,1.414l10.294,10.294l-10.294,10.294c-0.39,0.39 -0.391,1.024 2.22045e-16,1.414c0.391,0.391 1.024,0.39 1.414,0l10.294,-10.294l10.293,10.292c0.39,0.391 1.023,0.391 1.414,0c0.391,-0.39 0.39,-1.023 0,-1.413Z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}

.glightbox-open .glightbox-clean .ginner-container {
  border-radius: $base-border-radius;
  overflow: hidden;
}

.glightbox-open .glightbox-clean .gslide-description {
  background: rgb(242,242,242);
}
.glightbox-open .glightbox-clean .gslide-description.description-bottom {
  border-top: 1px rgb(220,220,220) solid;
  margin-top: -2rem;
}
.glightbox-open .glightbox-clean .gslide-description.description-top {
  border-bottom: 1px rgb(220,220,220) solid;
}

.glightbox-open .glightbox-clean .gslide-external {
  max-height: 100vh !important;
  max-width: 100vw !important;
}

.glightbox-open .gnext,
.glightbox-open .gprev {
  display: none !important;
}

.glightbox-open .glightbox-clean .gslide-external > iframe {
  height: 700px !important;
  padding-top: 1rem !important;
  max-height: 100vh !important;
}
