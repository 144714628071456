$item-v-spacing: 1.5rem;

.c-jobs {
  padding: .75rem 1.2rem;
  text-align: center;
  // max-width: 75ch;
  margin: #{-$item-v-spacing} auto;

  h3 {
    @include font-size(18px);

  }
}

.c-job {
  margin: $item-v-spacing auto;
  position: relative;
  max-width: 80ch;

  h4 {
    margin-top: 1.5em;
    margin-bottom: .25em;
    @include font-size(16px);
    color: $color-gray;
    font-weight: normal;
    // font-style: italic;
  }

  p {
    @include font-size(16px);
    margin: 0;
  }

  &:not(:last-of-type):after {
    content: "";
    margin-top: $item-v-spacing;
    display: block;
    border-bottom: 2px $base-border-color solid;
  }
}
